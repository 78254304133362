var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"编辑API信息"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"simiot-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 14 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('template',{slot:"label"},[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" 设置为 0.0.0.0 时不限制IP ")]),_vm._v(" 调用API的IP地址1 "),_c('a-icon',{attrs:{"type":"question-circle"}})],2)],1),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['api_ip_1', {
          normalize: this.$lodash.trim,
          rules: [
            { required: true, message: '请输入IP地址1' },
            { pattern: /^((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))$/, message: '请输入正确的IP地址' } ]
        }]),expression:"['api_ip_1', {\n          normalize: this.$lodash.trim,\n          rules: [\n            { required: true, message: '请输入IP地址1' },\n            { pattern: /^((25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))\\.){3}(25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))$/, message: '请输入正确的IP地址' },\n          ]\n        }]"}]})],2),_c('a-form-item',{attrs:{"label":"调用API的IP地址2"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['api_ip_2', {
          normalize: this.$lodash.trim,
          rules: [
            { pattern: /^((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))$/, message: '请输入正确的IP地址' } ]
        }]),expression:"['api_ip_2', {\n          normalize: this.$lodash.trim,\n          rules: [\n            { pattern: /^((25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))\\.){3}(25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))$/, message: '请输入正确的IP地址' },\n          ]\n        }]"}]})],1),_c('a-form-item',{attrs:{"label":"调用API的IP地址3"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['api_ip_3', {
          normalize: this.$lodash.trim,
          rules: [
            { pattern: /^((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))$/, message: '请输入正确的IP地址' } ]
        }]),expression:"['api_ip_3', {\n          normalize: this.$lodash.trim,\n          rules: [\n            { pattern: /^((25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))\\.){3}(25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))$/, message: '请输入正确的IP地址' },\n          ]\n        }]"}]})],1),_c('a-form-item',{attrs:{"label":"查询接口调用限制"}},[_c('a-form-item',{style:({ display: 'inline-block', width: '15%', marginBottom: 0 })},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_limit_api_query', { valuePropName: 'checked' }]),expression:"['is_limit_api_query', { valuePropName: 'checked' }]"}]})],1),(_vm.isShowApiQueryRateSetting)?_c('a-form-item',{style:({ display: 'inline-block', width: '85%', marginBottom: 0 })},[_c('a-space',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['api_query_rate', {
              rules: [
                { required: true, message: '请输入调用次数' } ]
            }]),expression:"['api_query_rate', {\n              rules: [\n                { required: true, message: '请输入调用次数' },\n              ]\n            }]"}],staticStyle:{"width":"150px"},attrs:{"min":0,"max":10000000,"step":1,"precision":0}}),_c('span',[_vm._v("次 / 每分钟")])],1)],1):_vm._e()],1),_c('a-form-item',{attrs:{"label":"操作接口调用限制"}},[_c('a-form-item',{style:({ display: 'inline-block', width: '15%', marginBottom: 0 })},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_limit_api_operation', { valuePropName: 'checked' }]),expression:"['is_limit_api_operation', { valuePropName: 'checked' }]"}]})],1),(_vm.isShowApiOperationRateSetting)?_c('a-form-item',{style:({ display: 'inline-block', width: '85%', marginBottom: 0 })},[_c('a-space',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['api_operation_rate', {
              rules: [
                { required: true, message: '请输入调用次数' } ]
            }]),expression:"['api_operation_rate', {\n              rules: [\n                { required: true, message: '请输入调用次数' },\n              ]\n            }]"}],staticStyle:{"width":"150px"},attrs:{"min":0,"max":10000000,"step":1,"precision":0}}),_c('span',[_vm._v("次 / 每分钟")])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
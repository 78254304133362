<template>
  <a-modal
    v-model="isShow"
    centered
    :mask-closable="false"
    :ok-button-props="{ props: { loading: submitting } }"
    title="编辑API信息"
    @ok="handleSubmit"
  >
    <a-form
      class="simiot-compact-form"
      :form="form"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 14 }"
      @submit="handleSubmit"
    >

      <a-form-item>
        <template slot="label">
          <a-tooltip>
            <template slot="title">
              设置为 0.0.0.0 时不限制IP
            </template>
            调用API的IP地址1
            <a-icon type="question-circle" />
          </a-tooltip>
        </template>
        <a-input
          v-decorator="['api_ip_1', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入IP地址1' },
              { pattern: /^((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))$/, message: '请输入正确的IP地址' },
            ]
          }]"
        />
      </a-form-item>

      <a-form-item label="调用API的IP地址2">
        <a-input
          v-decorator="['api_ip_2', {
            normalize: this.$lodash.trim,
            rules: [
              { pattern: /^((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))$/, message: '请输入正确的IP地址' },
            ]
          }]"
        />
      </a-form-item>

      <a-form-item label="调用API的IP地址3">
        <a-input
          v-decorator="['api_ip_3', {
            normalize: this.$lodash.trim,
            rules: [
              { pattern: /^((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))$/, message: '请输入正确的IP地址' },
            ]
          }]"
        />
      </a-form-item>

      <a-form-item label="查询接口调用限制">
        <a-form-item :style="{ display: 'inline-block', width: '15%', marginBottom: 0 }">
          <a-checkbox v-decorator="['is_limit_api_query', { valuePropName: 'checked' }]" />
        </a-form-item>

        <a-form-item
          v-if="isShowApiQueryRateSetting"
          :style="{ display: 'inline-block', width: '85%', marginBottom: 0 }"
        >
          <a-space>
            <a-input-number
              :min="0"
              :max="10000000"
              :step="1"
              :precision="0"
              style="width: 150px;"
              v-decorator="['api_query_rate', {
                rules: [
                  { required: true, message: '请输入调用次数' },
                ]
              }]"
            />
            <span>次 / 每分钟</span>
          </a-space>
        </a-form-item>
      </a-form-item>

      <a-form-item label="操作接口调用限制">
        <a-form-item :style="{ display: 'inline-block', width: '15%', marginBottom: 0 }">
          <a-checkbox v-decorator="['is_limit_api_operation', { valuePropName: 'checked' }]" />
        </a-form-item>

        <a-form-item
          v-if="isShowApiOperationRateSetting"
          :style="{ display: 'inline-block', width: '85%', marginBottom: 0 }"
        >
          <a-space>
            <a-input-number
              :min="0"
              :max="10000000"
              :step="1"
              :precision="0"
              style="width: 150px;"
              v-decorator="['api_operation_rate', {
                rules: [
                  { required: true, message: '请输入调用次数' },
                ]
              }]"
            />
            <span>次 / 每分钟</span>
          </a-space>
        </a-form-item>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { findAgentApiInfoForm, updateAgentApiInfo } from '@/api/agent'

export default {
  name: 'EditAgentApiInfo',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    agentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      submitting: false,
      isShowApiQueryRateSetting: true,
      isShowApiOperationRateSetting: true,
      form: this.$form.createForm(this, { name: 'agent_api_info', onValuesChange: this.onValuesChange })
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchFormData()
  },
  methods: {
    fetchFormData() {
      findAgentApiInfoForm(this.agentId).then((res) => {
        this.form.setFieldsValue(res.data)
      })
    },

    onValuesChange(props, values) {
      if (values.is_limit_api_query !== undefined) {
        this.isShowApiQueryRateSetting = values.is_limit_api_query
      }

      if (values.is_limit_api_operation !== undefined) {
        this.isShowApiOperationRateSetting = values.is_limit_api_operation
      }
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          updateAgentApiInfo(this.agentId, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
